import ListPage from '../list-page'
import ListContent from './list-content.vue'

export default {
  name: 'NotificationListPage',
  mixins: [ListPage],
  data () {
    return {
      queuedUpdate: false
    }
  },
  computed: {
    pageTitleParts () {
      return ['Benachrichtigungen']
    },
    pageContentComponents () {
      return ListContent
    },
    requestableLoading () {
      return false
    }
  },
  channels: {
    NotificationChannel: {
      received (data) {
        if (this.isVisible()) {
          this.fetchPageData()
        } else {
          this.queueUpdate()
        }
      }
    }
  },
  methods: {
    initPageRequestUrl () {
      return this.$apiEndpoints.notifications.index()
    },
    initPageRequestParams () {
      return {
        page: this.pages.current,
        filter_category: this.filters.values.filter_category
      }
    },
    isVisible () {
      return document.visibilityState === 'visible' && document.hasFocus()
    },
    focusHandler () {
      if (this.isVisible()) {
        this.queuedUpdate = false
        this.fetchPageData()
        document.removeEventListener('visibilitychange', this.focusHandler)
        window.removeEventListener('focus', this.focusHandler)
      }
    },
    queueUpdate () {
      if (this.isVisible()) {
        return this.focusHandler()
      }
      if (this.queuedUpdate) {
        return
      }
      this.updateQueued = true
      document.addEventListener('visibilitychange', this.focusHandler)
      window.addEventListener('focus', this.focusHandler)
    },
    filterTabs () {
      return {
        name: 'filter_category',
        items: [
          { text: 'Neu', value: 'NEW' },
          { text: 'Merkliste', value: 'BOOKMARKED', icon: 'mdi-bookmark-multiple' },
          { text: 'Erledigt', value: 'DONE' }
        ],
        counts: this.valueAttributeOrDefault('filter_category_counts', {}),
        default: 'NEW'
      }
    }
  }
}
