<script>
import Vue from 'vue'
import { PageContentable } from '../page'
import { ListPageContentable } from '../list-page'
import PageListHeader from 'components/page-list-header.vue'
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import DataListFilter from 'components/data-list/filter.vue'
import NotificationListItem from 'list-items/notification-list-item.vue'
import { routeFor } from 'helpers/route'
import map from 'lodash/map'

export default {
  name: 'NotificationListContent',
  components: {
    PageListHeader,
    DataListFilter,
    NotificationListItem
  },
  mixins: [PageContentable, ListPageContentable],
  computed: {
    result () {
      return this.value ? this.value.result : []
    }
  },
  watch: {
    result: {
      handler (value) {
        if (document.visibilityState === 'visible' && document.hasFocus()) {
          this.sendDeliveredRequest(value.filter(notification => !notification.delivered_at))
          this.sendReadRequest()
        }
      },
      immediate: true
    }
  },
  methods: {
    ...{ routeFor },
    updateFilter (value, field) {
      this.$emit('update:prop', { prop: 'filter', value, info: field })
    },
    sendDeliveredRequest (notifications) {
      if (notifications.length > 0) {
        const requestable = new (Vue.extend(Requestable))()
        requestable.request({
          method: Request.PATCH,
          url: this.$apiEndpoints.notifications.delivered()
        }, null, { notification_ids: map(notifications, 'id') })
      }
    },
    sendReadRequest () {
      const requestable = new (Vue.extend(Requestable))()
      requestable.request({
        method: Request.PATCH,
        url: this.$apiEndpoints.notifications.readAll()
      })
    }
  }
}
</script>
<template>
  <div>
    <page-list-header title="Benachrichtigungen" />

    <data-list-filter
      v-model="filters"
      @update:filter="$emit('update:prop', { prop: 'filters', value: $event.value, info: $event.field })"
    />

    <v-timeline
      v-if="!pageRequestLoading && !pageRequestHasError && result.length"
      class="ml-n5"
      dense
    >
      <notification-list-item
        v-for="notification in result"
        :key="notification.id"
        :notification="notification"
        :current-page="pages.current"
        :current-filter-category="filters.values.filter_category"
        :show-close="filters.values.filter_category !== 'DONE'"
        @request-success-data="$emit('input', $event)"
      />
    </v-timeline>

    <div
      v-if="pageRequestLoading"
      class="py-6 text-center"
    >
      <v-progress-circular
        color="primary"
        indeterminate
      />
    </div>

    <v-pagination
      v-if="!pageRequestLoading && result && pages.total() > 1"
      v-model="pages.current"
      :length="pages.total()"
      circle
      class="mt-6"
      @input="$emit('update:prop', { prop: 'pages', value: pages })"
    />

    <p class="mt-4 text-center">
      <template v-if="filters.values.filter_category === 'NEW'">
        Samarbeid räumt die Benachrichtigungen automatisch für Dich auf.
      </template>
      <template v-else-if="filters.values.filter_category === 'BOOKMARKED'">
        Samarbeid hebt gemerkte Benachrichtigungen für Dich auf.
      </template>
      <template v-else-if="filters.values.filter_category === 'DONE'">
        Samarbeid löscht alte Benachrichtigungen automatisch für Dich.
      </template>
    </p>

    <i18n
      v-if="value"
      path="user.emailNotificationInterval.message"
      tag="p"
      class="text-center"
    >
      <template #interval>
        <b>{{ value.userNotificationInterval }}</b>
      </template>
      <template #link>
        <router-link
          :to="routeFor('user_settings_notifications', $config.current_user.id)"
        >{{ $t('user.emailNotificationInterval.linkText') }}</router-link>
      </template>
    </i18n>
  </div>
</template>
<style>
/* This combination of reducing the padding-top of the timeline and the padding-bottom of the items while adding margin-top and padding-top to the timeline>a keeps the placement exactly as before while having the hover-background centered */
.v-timeline {
  padding-top: 8px!important;
}
.v-timeline > a:hover {
  background-color: #f5f5f5;
  border-radius: 4px;
}
.v-timeline > a {
  display: block;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  text-decoration: none;
}
.v-timeline > a > .v-timeline-item {
  padding-bottom: 0.5rem;
}
.v-timeline > a > .v-timeline-item > .v-timeline-item__body > div > .ml-2 {
  /* this is the actions slot */
  margin-right: 8px; /* don't touch the side of the hover-background */
  flex-shrink: 0; /* don't line-break buttons if content is too big */
}
.v-timeline > a > .v-timeline-item .v-timeline-item__dot {
  border: 1px solid transparent;
  box-sizing: content-box;
}
.v-timeline > a:hover > .v-timeline-item .v-timeline-item__dot {
  border: 1px solid #bdbdbd;
  box-sizing: content-box;
}
</style>
