<script>
import VSnackbars from 'v-snackbars'
import StandaloneEvent from './standalone-event.vue'
import Vue from 'vue'
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import { routeFor } from 'helpers/route'
import { VProgressLinear } from 'vuetify/lib/components'

export default {
  name: 'Snackbars',
  components: {
    VSnackbars,
    StandaloneEvent,
    VProgressLinear
  },
  data () {
    return {
      newNotifications: [],
      timeout: 10000,
      hover: false
    }
  },
  channels: {
    NotificationChannel: {
      connected () { this.$debugLog('connected to NotificationChannel via vue') },
      rejected () {},
      received (data) {
        if (document.visibilityState === 'visible' && document.hasFocus()) {
          this.sendDeliveredRequest(data.id)
        }
        this.addNotification(data)
      },
      disconnected () {}
    }
  },
  mounted () {
    this.$cable.subscribe({
      channel: 'NotificationChannel'
    })
  },
  methods: {
    ...{ routeFor },
    sendDeliveredRequest (id) {
      const requestable = new (Vue.extend(Requestable))()
      requestable.request({ method: Request.PATCH, url: this.$apiEndpoints.notifications.delivered() }, null, { notification_ids: [id] })
    },
    sendReadRequest (id) {
      const requestable = new (Vue.extend(Requestable))()
      requestable.request({ method: Request.PATCH, url: this.$apiEndpoints.notifications.read() }, null, { notification_ids: [id] })
    },
    sendDoneRequest (id) {
      const requestable = new (Vue.extend(Requestable))()
      requestable.request({ method: Request.PATCH, url: this.$apiEndpoints.notifications.done(id) })
    },
    addNotification (data) {
      const currentNotification = { ...data, remaining: this.timeout }
      this.newNotifications.push(currentNotification)
      const step = 1000 // same as transition duration in scoped style below
      const interval = setInterval(() => {
        if (!this.hover) {
          currentNotification.remaining -= step
        }
        if (currentNotification.remaining <= -step) {
          clearInterval(interval)
          setTimeout(() => {
            this.newNotifications.splice(this.newNotifications.indexOf(currentNotification), 1)
          }, 200)
        }
      }, step)
    }
  }
}
</script>
<template>
  <div
    id="notification-snackbars"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <v-snackbars
      :messages.sync="newNotifications"
      :timeout="-1"
      color="white"
      light
      multi-line
      vertical
      top
      right
    >
      <template #default="{ message }">
        <v-progress-linear
          absolute
          top
          reverse
          :value="Math.floor(100 * (message.remaining / timeout))"
        />
        <standalone-event :event="message.event" />
      </template>
      <template #action="{ close, message }">
        <v-btn
          text
          @click="sendDoneRequest(message.id);close()"
        >
          Erledigt
        </v-btn>
        <v-btn
          text
          @click="sendReadRequest(message.id);close()"
        >
          Schließen
        </v-btn>
      </template>
    </v-snackbars>
  </div>
</template>
<style scoped>
  .v-progress-linear {
    transition: 1s linear;
  }
</style>
